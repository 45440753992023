

(function( ){


	// callback 	サーバータイム取得後のコールバック関数を指定してください。
	// timeOffset 	UTCからの時差を指定してください。
	//				日本9時間
	//				台湾8時間
	// src			サーバー時間の取得先URL、基本は変更なし

	ServerTime = function(callback, timeOffset, src){

		timeOffset = timeOffset || 9;
		src = src || "//get-date-for-site.s3-ap-northeast-1.amazonaws.com/index.html?";

		var _this = this , i ;

		_this.date = null;
		_this.time = null;
		_this.dateType = null;

		var client = new XMLHttpRequest();
		client.onreadystatechange = function(evt) {

			if( this.readyState == this.HEADERS_RECEIVED ) {
				try {
					var date_str = client.getResponseHeader("Date").replace(/GMT/gi, "");
					_this.date = new Date(date_str);
					_this.dateType = "server" ;
				}catch(err) {
					date_str = null;
					_this.date = new Date();
					_this.dateType = "local" ;
				}

				if(date_str) {
					_this.date.setHours(_this.date.getHours()+timeOffset) ;
				}
				_this.time = _this.date.getTime();


				if(callback){
					var evt = {
						type: "complete",
						dateType: _this.dateType,
						date: _this.date,
						time: _this.time,
						target: _this
					}
					callback(evt);
				}
			}
		}
		client.open("GET", src + new Date().getTime(), true);
		client.send();
	}


} )( ) ;
