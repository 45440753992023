(function( ){


//------------------------------------------------------------------------------------------------------------------------------


  var Main = function( )
  {
    var _this = this , i ;

    // Youtube API 読み込み
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    showMenu();
    showYoutube();
    switchBg();
  }

  var switchBg = function() {
    var bgDOM = $('body')
    var tmpNum = Math.floor(Math.random()*5) + 1;
    switch(tmpNum){
      case 1:
        bgDOM.addClass('bg01');
        break; 
      case 2:
        bgDOM.addClass('bg02');
        break; 
      case 3:
        bgDOM.addClass('bg03');
        break; 
      case 4:
        bgDOM.addClass('bg04');
        break; 
      case 5:
        bgDOM.addClass('bg05');
        break; 
      default:
        bgDOM.addClass('bg01');
        break
    }
  }

  var showMenu = function() {
    $('.js-menu, .sp-menu-link').on('click', function() {
      $('body').toggleClass('is-menu-open');
    });
  }


  var showYoutube = function() {
    $('.js-youtube').on('click', function() {
      
      var id = $(this).data('url');
      id = id.split('?v=')[1];
      id = id.split('&')[0];

      $('.js-youtubeContents').fadeIn(500);
      onYouTubePlayerAPIReady(id);
    });

    $('.js-youtubeClose, .js-youtubeContents').on('click', function() {
      $('.js-youtubeContents').fadeOut(500, function() {
        player.stopVideo();

        $('.js-YoutubeWrapper').empty();
        $('.js-YoutubeWrapper').append('<div id="js-YoutubeMovie"></div>');
      });
    });

    $('.js-YoutubeWrapper').on('click', function(e) {
      e.stopPropagation();
    });
  }

  /*
  修正
  */

 var ytStatus;
 var fadeSpeed = 400;

  var onYouTubePlayerAPIReady = function(id) {
    player = new YT.Player('js-YoutubeMovie', {
      height: '360',
      width: '640',
      videoId: id,
      autoplay: 1,
      rel: 0,
      'origin': location.protocol + '//' + location.hostname + "/",
      playerVars: {
        playsinline: 1
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  }

  function onPlayerReady(event) {
    $('.js-youtubeContents').fadeIn(fadeSpeed);
    var myEle = event.target;
    myEle.playVideo();
    myEle.mute();
    ytStatus = myEle.getPlayerState();
  }

  function onPlayerStateChange(event) {
    ytStatus = event.target.getPlayerState();
    if (ytStatus == YT.PlayerState.ENDED) {
      closeSplash();
    }
  }

  function closeSplash() {
    $('.js-youtubeContents').fadeOut(fadeSpeed, function() {
      player.stopVideo();
      $('.js-YoutubeWrapper').empty();
      $('.js-YoutubeWrapper').append('<div id="js-YoutubeMovie"></div>');
    });
  }

//------------------------------------------------------------------------------------------------------------------------------


  window.requestAnimationFrame =  window.requestAnimationFrame ||
                  window.webkitRequestAnimationFrame ||
                  function( func ){ return setTimeout( func , 1000/60 ) ; } ;


  window.cancelAnimationFrame =   window.cancelAnimationFrame ||
                  window.webkitCancelAnimationFrame ||
                  function( id ){ clearTimeout( id ) ; } ;


  document.readyState === "complete"
    ? setTimeout( function(){ new Main( ) ; } , 1 )
    : document.addEventListener( "DOMContentLoaded", function(){ new Main( ) ; } ) ;


//------------------------------------------------------------------------------------------------------------------------------


} )( ) ;
