(function( ){

  var tagID  = {
    animemovie: "UA-65259927-4",
  }

  switch( location.host ){
    case "anime-movie.monster-strike.com" :
      tagID = tagID["animemovie"] ;
      break ;

    default :
      tagID = null ;
  }


  if( tagID ){
    //<!-- Global site tag (gtag.js) - Google Analytics -->
    var script_gtag = document.createElement( "script" ) ;
    script_gtag.async = true ;
    script_gtag.src = "https://www.googletagmanager.com/gtag/js?id=" + tagID ;
    document.body.appendChild( script_gtag ) ;

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', tagID);

    window.gtag = gtag ;
  }else {
    console.log( "本番以外の環境では、トラッキング送信の代わりにコンソール出力を行います。\nこのコンソールは本番環境では出力されません。" )
  }


//------------------------------------------------------------------------------------------------------------------------------


	Tracking = function( )
  {
    var _this = this , i ;


    // どこまでスクロールしたかのトラッキングをとるためのクラス
    if( tagID ){
      new ScrollDepthTrack( ) ;
    }


    // クリックトラッキング対象を追加します、すでに追加されている場合は、除外する
    Tracking.add = function( target ){

      if(target.isClickTracking){
        return ;
      }

      target.addEventListener( "click", Tracking.onClick );
      target.isClickTracking = true ;
  	}


    // ターゲットの子孫要素からトラッキング対象をパースします
    Tracking.parse = function( rootTarget ){

      rootTarget = rootTarget == undefined ? document : rootTarget ;

      var target = rootTarget.getElementsByClassName( "js-tracking" );
      for( i = 0 ; i < target.length ; i++ ) {
        Tracking.add( target[i] ) ;
      }
    }


    // ターゲットにイベント付与
    Tracking.onClick = function( evt )
    {
      var eventValue = evt.currentTarget.getAttribute( "data-tracking" ) ;
      eventValue = eventValue.split( "," ) ;

      if( evt.currentTarget.getAttribute( "data-tracking-once" ) != undefined ) {
        if( Tracking.sentTrack.indexOf( eventValue ) != -1 ){
          return ;
        }else {
          Tracking.sentTrack.push( eventValue ) ;
        }
      }

      Tracking.send(eventValue[0], eventValue[1], eventValue[2], eventValue[3]) ;
    }


    // トラッキング送信
    Tracking.send = function( category, action, label, value )
    {
      if( !tagID ){
        console.log( "トラッキング : " + category + ", " + action + ", " + label + ( value ? ", " + value : "" ) );
        return ;
      }

      var gtag = window.gtag || null ;
      if( gtag ){
        gtag('event', action , {
          event_category: category ,
          event_action: action ,
          event_label: label ,
          event_value: value?value:null } ) ;
        }
    }


    // 初回１度だけパースする
    Tracking.parse() ;
  }


  Tracking.sentTrack = [ ] ;


//------------------------------------------------------------------------------------------------------------------------------


	var ScrollDepthTrack = function( )
	{

		var i ;

		var pathname = location.pathname ;
		if( /\/$/.test(pathname) ){
			pathname = pathname + "index.html" ;
		}

    // スクロールトラッキングをとりすぎると、トラッキングの回数制限に引っかかるため、
    // 1/100の人だけサンプリングしてトラッキング
    if( Math.random() > 0.02 ){
    	return ;
    }

    var sentPercent = 0 ;
    var _onScroll = function( evt ) {

      var nowPercent = Math.floor( ( window.pageYOffset / ( document.body.offsetHeight-window.innerHeight ) ) * 10 ) * 10 ;

      if( nowPercent == Infinity ){
        nowPercent = 0 ;
      }

      if( nowPercent > ScrollDepthTrack.trackData.percent ){

        ScrollDepthTrack.trackData.percent = nowPercent ;

        while( sentPercent <= ScrollDepthTrack.trackData.percent ){
          gtag('event', pathname , {
              event_category: 'scroll_%' ,
              event_action: pathname ,
              event_label: sentPercent+"%" ,
              non_interaction: true
          } ) ;

          // console.log( "スクロール計測 - パーセント" , sentPercent+"%" ) ;

          sentPercent += 20 ;

          if( sentPercent > 100 ){
            break ;
          }
        }

        if( sentPercent > 100 ){
          document.removeEventListener( "scroll" , _onScroll ) ;
        }
      }
    }

    document.addEventListener( "scroll" , _onScroll ) ;

  }
  ScrollDepthTrack.trackData =
  {
    section:[] ,
    percent:-1
  }


//------------------------------------------------------------------------------------------------------------------------------


  // DOMの準備が完了したら、呼ぶ
  document.readyState === "complete"
    ? setTimeout( function(){ new Tracking(); } , 1 )
    : document.addEventListener( "DOMContentLoaded", function(){ new Tracking(); } ) ;


//-------------------------------------------------------------------------------------------------------------------------------


} )( ) ;
