/**
 * 共通ページ内アンカー処理
 * 	- Tween.jsに依存
 */
(function(){

	/**
	 * 共通ページ内アンカー処理
	 * @method AnchorScroll
	 */
	var AnchorScroll = function( ) {

		/**
		 * 指定要素のページ上部からの位置を取得する処理
		 * @param  {Object} target     アンカー対象 HTML OBJECT
		 * @param  {Object} rootTarget スクロールの基準場所、指定しなければdocument
		 * @return {Number}            スクロール位置
		 */
		var onAnchorClick = function( evt ){
			evt.preventDefault( ) ;
			//

			var hash = evt.currentTarget.href.split( "#" )[1].split( "?" )[0] ;
			var top = 0;

			if( hash != "" ){
				var target = document.getElementById( hash ) ;
				target.id = "" ;
				location.href = "#" + hash ;
				target.id = hash ;
				top = getOffsetTop( target ) ;
			}

			if( window.Fit && window.Fit.zoomScale ){
				top = top * window.Fit.zoomScale ;
			}

			Tween.scroll( top , 0.8 , "easeInOutQuint" ) ;
		}

		var a = document.getElementsByTagName( "a" ) ;
		for( var i = 0 ; i < a.length ; i++ ){
			if( /#/.test( a[i].href ) ){
				var hrefSplit = a[i].href.split("#")[0].split( "/" ) ;
				if( hrefSplit[hrefSplit.length-1] == "" ) hrefSplit[hrefSplit.length-1] = "index.html" ;

				var currentHrefSplit = location.href.split("#")[0].split( "/" ) ;
				if( currentHrefSplit[hrefSplit.length-1] == "" )  currentHrefSplit[hrefSplit.length-1] = "index.html" ;

				if( hrefSplit[hrefSplit.length-1] != currentHrefSplit[hrefSplit.length-1] || hrefSplit[hrefSplit.length-2] != currentHrefSplit[hrefSplit.length-2] )  continue ;

				a[i].addEventListener( "click" , onAnchorClick ) ;
			}
		}

		//

		/**
		 * 指定要素のページ上部からの位置を取得する処理
		 * @param  {Object} target     アンカー対象 HTML OBJECT
		 * @param  {Object} rootTarget スクロールの基準場所、指定しなければdocument
		 * @return {Number}            スクロール位置
		 */
		var getOffsetTop = function( target , rootTarget ){
			rootTarget = rootTarget == undefined ? document : rootTarget ;
			var top = target.offsetTop ;
			var parent = target.parentNode ;

			while( parent && parent != rootTarget ){
				var parentStyle = parent.currentStyle || document.defaultView.getComputedStyle( parent , '' ) ;

				if( parent.offsetTop !== undefined && parentStyle.position != "static" ){
					top += parent.offsetTop ;
				}
				parent = parent.parentNode ;
			}

			return top ;
		}

	}


//------------------------------------------------------------------------------------------------------------------------------



	// DOMの準備が完了したら、呼ぶ
	document.readyState === "complete"
		? setTimeout( function (){ new AnchorScroll( ); } , 1 )
		: document.addEventListener( "DOMContentLoaded", function (){ new AnchorScroll( ); } ) ;



//------------------------------------------------------------------------------------------------------------------------------


})() ;
