

(function( ){


//------------------------------------------------------------------------------------------------------------------------------


	Share = function( ){

		var _this = this , i , temp ;

		var target = document.getElementsByClassName( "js-share" ) ;

		var data ;

		var isSP = ( /iphone|ipad|ipod|android/gi.test( navigator.userAgent ) ) ;
		var isPC = !isSP ;

		var LINE = "line" ;
		var TWITTER = "twitter" ;
		var FACEBOOK = "facebook" ;
		var WEIBO = "weibo" ;


		//--------------------------------------------------------------------
		//PCのときはwindowサイズ指定して投稿フォームを開く

		var onClick = function( evt , type ){
			if( isSP )	return ;

			switch( evt.type ){
				case "click" :
					switch( evt.currentTarget.shareType ){
						case TWITTER :
							evt.preventDefault( ) ;
							window.open( evt.currentTarget.getAttribute( "href" ) , 'share', 'height=257, width=680, menubar=no, toolbar=no, resizable=yes, scrollbars=yes');
							break ;

						case LINE :
							evt.preventDefault( ) ;
							window.open( evt.currentTarget.getAttribute( "href" ) , 'share', "width=600, height=630, menubar=no, toolbar=no, scrollbars=yes");
							break ;

						case FACEBOOK :
							evt.preventDefault( ) ;
							window.open( evt.currentTarget.getAttribute( "href" ) , 'share', "width=600, height=630, menubar=no, toolbar=no, scrollbars=yes");
							break ;

						case WEIBO :
							evt.preventDefault( ) ;
							window.open( evt.currentTarget.getAttribute( "href" ) , 'share', "width=650, height=485, menubar=no, toolbar=no, scrollbars=yes");
							break ;
					}
					break ;
			}
		}

		for( i = 0 ; i < target.length ; i++ ){
			temp = target[i] ;
			temp.shareType = temp.getAttribute("data-type") ;
			temp.addEventListener( "click" , onClick ) ;
		}


		//--------------------------------------------------------------------
		//weibo向けのパラメータ取得

		var lang = document.documentElement.getAttribute("lang") ;
		var og_img_url ;
		var meta = document.getElementsByTagName("meta") ;
		for( i = 0 ; i < meta.length ; i++ ){
			if( meta[i].getAttribute("property") == "og:image" ){
				og_img_url = meta[i].content ;
			}
		}


		//--------------------------------------------------------------------


		Share.createURL = {
			twitter:function( url, text, hash ){
				return "http://twitter.com/share?text=" + encodeURIComponent( text ) + "&url=" + encodeURIComponent( url ) + ( hash ? "&hashtags=" + encodeURIComponent( hash ) : "" ) ;
			},
			line:function( url, text, hash ){
				return isSP ? "http://line.me/R/msg/text/?" + encodeURIComponent( text + ( hash ? "\n #"+hash.split(",").join( " #" ) : "" ) + "\n" + url )
						 	: "https://social-plugins.line.me/lineit/share?" + "url=" + encodeURIComponent( url ) + "&text=" + encodeURIComponent( text + ( hash ? "\n#"+hash.split(",").join( " #" ) : "" ) ) ;
			},
			facebook:function( url ){
				return "https://www.facebook.com/share.php?u=" + encodeURIComponent( url ) ;
			},
			weibo:function( url, text, hash ){
				return "http://service.weibo.com/share/share.php?type=button&searchPic=false&style=simple&language="+lang+"&url=" + encodeURIComponent( url ) + "&title=" + encodeURIComponent( text + ( hash ? "\n#"+hash.split(",").join( " #" ) : "" ) ) + "&pic=" + encodeURIComponent( og_img_url ) ;
			}
		} ;


		//--------------------------------------------------------------------


		var setShareURL = function( )
		{
			data = Share.data ;

			var text , url , hash ;

			for( i = 0 ; i < target.length ; i++ ){
				temp = target[i] ;
				temp.target = "_blank" ;
				switch(temp.getAttribute("data-type")){

					case TWITTER :
						text = temp.getAttribute("data-text") || data.text ;
						url = temp.getAttribute("data-url") || data.url ;
						hash = temp.getAttribute("data-hash") || data.hash ;
						temp.href = Share.createURL.twitter( url, text, hash ) ;
						break ;

					case LINE :
						text = temp.getAttribute("data-text") || data.text ;
						url = temp.getAttribute("data-url") || data.url ;
						hash = temp.getAttribute("data-hash") || data.hash ;
						title = temp.getAttribute("data-title") || data.title || text ;
						temp.href = Share.createURL.line( url, text, hash, title ) ;
						break ;

					case FACEBOOK :
						url = temp.getAttribute("data-url") || data.url ;
						temp.href = Share.createURL.facebook( url ) ;
						break ;

					case WEIBO :
						text = temp.getAttribute("data-text") || data.text ;
						url = temp.getAttribute("data-url") || data.url ;
						hash = temp.getAttribute("data-hash") || data.hash ;
						temp.href = Share.createURL.weibo( url, text, hash ) ;
						break ;
				}
			}
		}

		setShareURL( ) ;

	}


	if (app && app.init && app.init.share) {
		Share.data = app.init.share;
	} else {
		Share.data ={
			text:"",
			url:"",
			hash:null,
			title:""
		} ;
	}


//------------------------------------------------------------------------------------------------------------------------------


	// DOMの準備が完了したら、呼ぶ
	document.readyState === "complete"
		? setTimeout( function(){new Share();} , 1 )
		: document.addEventListener( "DOMContentLoaded", function(){new Share();} ) ;


//------------------------------------------------------------------------------------------------------------------------------

} )( ) ;
