
(function( ){

//------------------------------------------------------------------------------------------------------------------------------

	ScrollEnterEvent = {} ;
	ScrollEnterEvent.add = function( target , obj ){

		// ターゲットの値が、配列や、getElementsByClassName()の戻り値そのままでも使えるようにするための処理です。
		// 配列をforで回して、それぞれのターゲットに処理をセッティングします。
		// obj値はそのまま渡すと、各値の参照先が共通になってしまうため、
		// （例えば、obj値をそのまま渡すと、すべてのobjのtargetの値が、forで回した最後のtargetになってしまう）
		// 構造をクローンしたものを引数に渡しています。
		if( target && target.length ){

			for( var i = 0 ; i < target.length ; i++ ){

				var cloneObj = {} ;
				for( var temp in obj ){

					cloneObj[temp] = obj[temp] ;

				}

				ScrollEnterEvent.add( target[i] , cloneObj ) ;
			}
			return ;
		}


		//


		if( !obj )	obj = {} ;

		obj.func = obj.func || null;
		obj.marginScale = obj.marginScale || 0;
		obj.autoRemove = obj.autoRemove == undefined ? true : obj.autoRemove;
		obj.enabled = obj.enabled == undefined ? true : obj.enabled;
		obj.autoFirstCheck = obj.autoFirstCheck || true;
		obj.timeout = obj.timeout || 0;
		obj.data = obj.data == undefined ? null : obj.data;
		obj.target = target ;
		obj.timeoutID = null ;

		obj.onEnter = obj.onEnter || obj.func || null ;
		obj.onExit = obj.onExit || null ;
		obj.onInside = obj.onInside || null ;
		obj.onOutside = obj.onOutside || null ;

		obj.isInside = false ;

		var _onScroll = function( evt ){

			if( !obj.enabled ){
				return ;
			}

			if( obj.timeout ){

				clearTimeout( obj.timeoutID ) ;
				obj.timeoutID = setTimeout( ScrollEnterEvent.check , obj.timeout*1000 , obj ) ;

			}else {

				ScrollEnterEvent.check( obj ) ;
			}

		}

		obj.onScroll = _onScroll ;

		window.addEventListener( "scroll" , _onScroll ) ;
		window.addEventListener( "resize" , _onScroll ) ;

		if( obj.autoFirstCheck ){
			window.requestAnimationFrame( function(){ if( obj ) _onScroll( ); } ) ;
		}

		return obj ;
	} ;
	ScrollEnterEvent.check = function( obj ){

		if( !obj.enabled )		return ;

		var target = obj.target ;
		var func = obj.func ;
		var margin = window.innerHeight * obj.marginScale ;
		var scrollY = window.pageYOffset ;
		var targetH = target.offsetHeight * FitContents.zoomScale ;

		//

		var targetY = target.offsetTop ;
		var parent = target.offsetParent ;
		while( parent && parent != document.body ){

			targetY += parent.offsetTop ;
			parent = parent.offsetParent ;
		}
		targetY *= FitContents.zoomScale ;

		//

		var enterAreaTop = targetY + margin ;
		var enterAreaBttom = targetY + targetH - margin ;
		var position = ( scrollY - enterAreaTop + window.innerHeight ) / ( enterAreaBttom - enterAreaTop + window.innerHeight ) ;

		if( scrollY + window.innerHeight > enterAreaTop && scrollY < enterAreaBttom ){

			if( !obj.isInside && obj.onEnter ){

				obj.onEnter( { type:"enter", target:target, data:obj.data, position:position } ) ;

				if( obj.autoRemove ){

					window.removeEventListener( "scroll" , obj.onScroll ) ;
					obj.enabled = false ;

				}
			}

			if( obj.onInside ){

				obj.onInside( { type:"inside", target:target, data:obj.data, position:position } ) ;

			}

			obj.isInside = true ;

		}else {

			if( obj.isInside && obj.onExit ){

				obj.onExit( { type:"exit", target:target, data:obj.data, position:position } ) ;

			}

			if( obj.onOutside ){

				obj.onOutside( { type:"outside", target:target, data:obj.data, position:position } ) ;

			}

			obj.isInside = false ;

		}


		func = null ;
		target = null ;
		obj = null ;
	} ;


//------------------------------------------------------------------------------------------------------------------------------

} )( ) ;